import vm from '@/viewModels/MainViewModel';

export const renderThemeByUf = (uf: string) => {

    const federativeUnit = vm.federativeUnits.find(x => x.uf == uf);

    if (federativeUnit?.theme) {
        vm.theme = federativeUnit?.theme ?? 'default-theme';
    }
};

export const renderThemeByName = (theme: string) => {
    document.documentElement.className = theme;
    vm.theme = theme;
};