import { createRouter, createWebHistory, NavigationGuard, RouteRecordRaw } from 'vue-router';
import vm from '@/viewModels/MainViewModel';

const LoginMainView = () => import(/* webpackChunkName: "login" */  '@/views/login/LoginMainView.vue');
const RegisterPasswordView = () => import(/* webpackChunkName: "login" */  '@/views/login/RegisterPasswordView.vue');
const ResetPasswordView = () => import(/* webpackChunkName: "login" */  '@/views/login/ResetPasswordView.vue');

// ============= Admin =============
const AdminMainPage = () => import(/* webpackChunkName: "admin" */ '@/views/admin/AdminMainPage.vue');
const MenuPage = () => import(/* webpackChunkName: "admin" */ '@/views/admin/MenuPage.vue');
const ExamView = () => import(/* webpackChunkName: "admin" */ '@/views/admin/exame/ExamView.vue');

const MyAuditView = () => import(/* webpackChunkName: "admin" */ '@/views/admin/meus-exames/MyAuditView.vue');
const MyPhotoAuditView = () => import(/* webpackChunkName: "admin" */ '@/views/admin/meus-exames/MyPhotoAuditView.vue');
const DisqualificationReason = () => import(/* webpackChunkName: "admin" */ '@/views/admin/razao-justificativas/DisqualificationReasonView.vue');
// ============= Support =============
const SupportMainView = () => import(/* webpackChunkName: "admin" */ '@/views/support/SupportMainView.vue');
const CreateusersView = () => import(/* webpackChunkName: "admin" */ '@/views/support/CreateusersView.vue');

// ============= Coordenador =============
const TransferExaminerView = () => import(/* webpackChunkName: "monitor" */ '@/views/coordenador/TransferExaminerView.vue');

// ============= Relatório ==============
const ExamReportView = () => import(/* webpackChunkName: "report" */ '@/views/reports/ExamReportView.vue');

// ============= Navigation Guards ============
const adminNavigationGuard: NavigationGuard = (to, from, next) => vm.user != null ? next() : next({ name: 'login' });

// ============= Demo ==============
const DemoRegisterView = () => import(/* webpackChunkName: "demo" */ '@/views/demo/DemoRegisterView.vue');
const DemoCaptureBiometryView = () => import(/* webpackChunkName: "demo" */ '@/views/demo/DemoCaptureBiometryView.vue');
const DemoFinishedBiometryView = () => import(/* webpackChunkName: "demo" */ '@/views/demo/DemoFinishedBiometryView.vue');

// ============= Certificates ==============
const FinishedExamView = () => import(/* webpackChunkName: "certificates" */ '@/views/certificates/FinishedExamView.vue');
const CertificateView = () => import(/* webpackChunkName: "certificates" */ '@/views/certificates/CertificateView.vue');

const routes: Array<RouteRecordRaw> = [
    {
        path: '/login',
        name: 'login',
        component: LoginMainView
    },
    {
        path: '/reset-password/:cpf',
        name: 'reset-password',
        component: ResetPasswordView,
    },
    {
        path: '/register-password/:cpf',
        name: 'register-password',
        component: RegisterPasswordView
    },
    {
        path: '/certificate',
        name: 'certificate',
        component: CertificateView
    },
    {
        path: '/',
        component: AdminMainPage,
        beforeEnter: adminNavigationGuard,
        children: [
            {
                path: '/finished-exam-view',
                name: 'finished-exam-view',
                meta: {
                    title: "Emissão de certificados"
                },
                component: FinishedExamView,
            },
            {
                path: '',
                name: 'admin',
                component: MenuPage
            },
            {
                path: 'exames/:schedulingId',
                name: 'exames-by-scheduling-id',
                component: ExamView
            },
            {
                path: 'exames',
                name: 'exames',
                component: ExamView
            },
            {
                path: 'auditoria/:id',
                name: 'auditoria',
                component: MyAuditView
            },
            {
                path: 'justificativas',
                name: 'justificativas',
                component: DisqualificationReason
            },
            {
                path: '/change-examiner',
                name: 'change-examiner',
                component: TransferExaminerView
            },
            {
                path: 'photo-audit/:examId',
                name: 'photo-audit',
                component: MyPhotoAuditView
            },
            {
                path: 'create-users',
                name: 'create-users',
                component: CreateusersView
            }
        ]
    },
    // =============== RELATÓRIOS ============
    {
        path: '/exam-report/:id',
        name: 'exam-report',
        meta: {
            hideLibras: true,
            adminGuard: true
        },
        component: ExamReportView
    },
    // =============== DEMO ============
    {
        path: '/demo-register/',
        name: 'demo-register',
        meta: {
            hideLibras: true,
            adminGuard: true
        },
        component: DemoRegisterView
    },
    {
        path: '/demo-biometry/:cpf',
        name: 'demo-biometry',
        meta: {
            hideLibras: true,
            adminGuard: true
        },
        component: DemoCaptureBiometryView
    },
    {
        path: '/demo-finished/',
        name: 'demo-finished',
        meta: {
            hideLibras: true,
            adminGuard: true
        },
        component: DemoFinishedBiometryView
    }

];


const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
});


router.beforeEach(async (to) => {
    if (to.meta['title']) {
        vm.pageTitle = (to.meta['title'] as any).toString();
    }
    else {
        vm.pageTitle = "";
    }
});

export default router;
