
import { defineComponent } from 'vue';
import './assets/styles/main.css';
import './assets/styles/transitions.css';
import packageInfo from '../package.json';
import { deviceId } from './utils/stringFunctions';
import vm from "@/viewModels/MainViewModel";

vm.appVersion = packageInfo.version?.replace("+", ".");
const device: string = deviceId();

console.info(`Aplicação de auditoria iniciada. Versão: ${vm.appVersion}, deviceId: ${device}`);

const App = defineComponent({
});

export default App;
