
import { defineComponent } from 'vue';

const Modal = defineComponent({
    props: {
        isOpened: {
            type: Boolean,
            default: false
        },
        canClose: {
            type: Boolean,
            default: true
        },
        width: {
            type: String,
            default: '340px'
        }
    }
});

export default Modal;
