import { createApp } from 'vue';

import '@/assets/styles/themes/valid-theme.css';
import alert from '@/hooks/alert';
import { parseUrlParts } from "@/utils/urlFunctions";
import vm from "@/viewModels/MainViewModel";
import PrimeVue from 'primevue/config';
import App from './App.vue';
import alertAudit from './hooks/alertAudit';
import router from './router';

const urlParts = parseUrlParts(window.location.href);

if (urlParts.tenant.length != 2) {
    vm.theme = localStorage.getItem("theme") ?? "default-theme";
}
else {
    vm.federativeUnitSelected = urlParts.tenant.toUpperCase();
}

const app = createApp(App);

app.use(router);
app.use(alert);
app.use(alertAudit);
app.use(PrimeVue, {
    locale: {
        dayNames: ["Domingo", "Segunda", "Terça", "Quarta", "Quinta", "Sexta", "Sábado"],
        dayNamesShort: ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sab"],
        dayNamesMin: ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sab"],
        monthNames: ["Janeiro","Fevereiro","Março","Abril","Maio","Junho","Julho","Agosto","Setembro","Outubro","Novembro","Dezembro"],
        monthNamesShort: ["Jan", "Fev", "Mar", "Abr", "Mai", "Jun","Jul", "Ago", "Set", "Out", "Nov", "Dez"],
        today: "Hoje",
        weekHeader:"Sm",
        firstDayOfWeek:	0,
        dateFormat:	"mm/dd/yy",
        weak: "Semana"
    }
});

app.mount('#app');