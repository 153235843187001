
import { defineComponent } from 'vue';

const Icon = defineComponent({
    props: {
        icon: {
            type: String,
            required: true
        },
        size: {
            type: String,
            default: "20px"
        },
        color: {
            type: String,
            default: "var(--primary-color)"
        }
    }
});

export default Icon;
