export default interface UrlParts {
    env: string;
    tenant: string; 
    domain: string; 
    route: string; 
}

export const getQueryParam = (param: string): string | null => {
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.get(param);
};

export const parseUrlParts = (url: string): UrlParts => {
    const regexWithTenant = /^https?:\/\/([a-zA-Z0-9_-]+)\.([a-zA-Z0-9_-]+)\.([a-zA-Z0-9_.-]+\.[a-zA-Z]+)(:\d+)?(\/[a-zA-Z0-9_/-]*)?$/;
    const regexWithoutTenant = /^https?:\/\/([a-zA-Z0-9_-]+)\.([a-zA-Z0-9_.-]+\.[a-zA-Z]+)(:\d+)?(\/[a-zA-Z0-9_/-]*)?$/;
    const regexOnlyEnv = /^https?:\/\/([a-zA-Z0-9:._-]+)(\/[a-zA-Z0-9_/-]*)?$/;

    let match = regexWithTenant.exec(url);

    if (match) {
        return {
            env: match[1],
            tenant: match[2],
            domain: match[3] + (match[4] || ''), // Inclui a porta, se existir
            route: match[5] || ''
        } as UrlParts;
    }

    match = regexWithoutTenant.exec(url);

    if (match) {
        return {
            env: match[1],
            tenant: '',
            domain: match[2] + (match[3] || ''), // Inclui a porta, se existir
            route: match[4] || ''
        } as UrlParts;
    }

    match = regexOnlyEnv.exec(url);

    if (match) {
        return {
            env: match[1],
            tenant: '',
            domain: '',
            route: match[2] || ''
        } as UrlParts;
    }

    return {
        env: '',
        tenant: '',
        domain: '',
        route: ''
    } as UrlParts;

};
