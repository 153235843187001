
import { defineComponent, ref } from 'vue';
import Modal from './Modal.vue';
import Icon from './Icon.vue';

interface Option {
    title: string;
    action?: (args?: any) => void;
    primary?: boolean;
}

const Alert = defineComponent({
    components: { Modal, Icon },
    setup() {
        const actions = ref<Option[]>([]);

        const icon = ref('alert');
        const title = ref('Opss...');
        const message = ref('');
        const isOpened = ref(false);
        const detail = ref('');
        const color = ref('');

        const displayAlert = (options: {
            title?: string;
            message: string;
            actions?: Option[];
            icon?: string;
            detail?: string;
            color?: string;
        }) => {
            actions.value = options.actions || [];
            icon.value = options.icon || 'alert';
            title.value = options.title || "Opss...";
            message.value = options.message;
            detail.value = options.detail || '';
            isOpened.value = true;
            color.value = options.color || 'var(--primary-color)';
        };

        const loadAction = (action?: () => void) => {
            isOpened.value = false;
            setTimeout(() => {
                if (action != null) {
                    action();
                }
            }, 300);
        };

        return { icon, title, message, isOpened, detail, loadAction, displayAlert, actions, color };
    }
});

export default Alert;
