
import { defineComponent, ref } from 'vue';
import Modal from './Modal.vue';
import Icon from './Icon.vue';
import { maskCpf } from '@/utils/stringFunctions';
import AuditDTO from '@/dtos/AuditDTO';

const AlertAudit = defineComponent({
    components: { Modal, Icon },
    setup() {

        const isOpened = ref(false);
        const title = ref("");
        const messageAuditor = ref("");
        const auditor = ref<AuditDTO | null>();
        const message = ref("");
        const audit = ref<AuditDTO[]>([]);

        const displayAlert = (options: {
            messageAuditor?: string;
            auditor?: AuditDTO;
            message: string;
            title?: string;
            audit?: AuditDTO[];
        }) => {
            isOpened.value = true;
            title.value = options.title || "";
            messageAuditor.value = options.messageAuditor || "";
            auditor.value = options.auditor || null;
            message.value = options.message;
            audit.value = options.audit || [];
        };

        return { maskCpf, title, messageAuditor, auditor, message, isOpened, displayAlert, audit };
    }
});

export default AlertAudit;
