import { renderSlot as _renderSlot, createVNode as _createVNode, Transition as _Transition, withCtx as _withCtx, withModifiers as _withModifiers, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock(_Transition, { name: "fade" }, {
    default: _withCtx(() => [
      (_ctx.isOpened)
        ? (_openBlock(), _createBlock("div", {
            key: 0,
            class: "fixed top-0 right-0 w-full h-screen z-50 bg-black bg-opacity-40 flex items-center justify-center",
            onClick: _cache[1] || (_cache[1] = _withModifiers(($event: any) => (_ctx.canClose ? _ctx.$emit('update:isOpened', false) : null), ["self"]))
          }, [
            _createVNode(_Transition, {
              name: "modalAppear",
              appear: ""
            }, {
              default: _withCtx(() => [
                _createVNode("div", {
                  class: "bg-white rounded-xl text-center",
                  style: [{"max-width":"calc(100%-40px)"}, {width: _ctx.width}]
                }, [
                  _renderSlot(_ctx.$slots, "default")
                ], 4)
              ]),
              _: 3
            })
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}