import { Plugin, createApp } from 'vue';
import AlertAudit from '@/components/AlertAudit.vue';
import AuditDTO from '@/dtos/AuditDTO';

let useAlertAudit: () => ((options: {
    messageAuditor?: string;
    auditor?: AuditDTO;
    message: string;
    title?: string;
    audit?: AuditDTO[];
}) => void
);

const alertAudit: Plugin = {
    install: () => {
        const alertRoot = createApp(AlertAudit).mount(document.body.appendChild(document.createElement('div')));
        useAlertAudit = () => (alertRoot as any).displayAlert;
    }
};

export { useAlertAudit };

export default alertAudit;