import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "flex flex-col items-center p-7" }
const _hoisted_2 = { class: "text-xl font-medium mb-2" }
const _hoisted_3 = {
  key: 0,
  class: "alert__detail"
}
const _hoisted_4 = { class: "w-full border-t border-gray-200 flex" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")
  const _component_Modal = _resolveComponent("Modal")

  return (_openBlock(), _createBlock(_component_Modal, { isOpened: _ctx.isOpened }, {
    default: _withCtx(() => [
      _createVNode("div", _hoisted_1, [
        _createVNode(_component_Icon, {
          icon: _ctx.icon,
          color: _ctx.color,
          size: "33px",
          class: "mb-5"
        }, null, 8, ["icon", "color"]),
        _createVNode("h2", _hoisted_2, _toDisplayString(_ctx.title), 1),
        _createVNode("p", { innerHTML: _ctx.message }, null, 8, ["innerHTML"]),
        (_ctx.detail.length)
          ? (_openBlock(), _createBlock("p", _hoisted_3, [
              _createVNode("small", null, _toDisplayString(_ctx.detail), 1)
            ]))
          : _createCommentVNode("", true)
      ]),
      _createVNode("footer", _hoisted_4, [
        (_ctx.actions.length == 0)
          ? (_openBlock(), _createBlock("button", {
              key: 0,
              class: "outline-none h-16 flex items-center justify-center flex-1",
              onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.isOpened = false))
            }, " Ok "))
          : (_openBlock(true), _createBlock(_Fragment, { key: 1 }, _renderList(_ctx.actions, (option, index) => {
              return (_openBlock(), _createBlock("button", {
                class: ["outline-none h-16 flex items-center justify-center flex-1 border-l first:border-l-0 border-gray-200", { 'font-medium text-primary': option.primary }],
                key: index,
                onClick: ($event: any) => (_ctx.loadAction(option.action))
              }, _toDisplayString(option.title), 11, ["onClick"]))
            }), 128))
      ])
    ]),
    _: 1
  }, 8, ["isOpened"]))
}