import { openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock("div", {
    class: "symbol",
    style: [{'width': _ctx.size},
                  {'height': _ctx.size},
                  {'background-color': _ctx.color},
                  {'mask-image': 'url('+ require('@/assets/icons/'+_ctx.icon+'.svg') +')'}]
  }, null, 4))
}